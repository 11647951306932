import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  totalpage: 0,
}

export const savedSlice = createSlice({
  name: 'savedR',
  initialState,
  reducers: {
    setSavedGames: (state, action) => {
      state.data = action.payload.data // Set the saved games data
      state.totalpage = action.payload.total_page
      state.isSuccess = true
    },
  },
})

export const { setSavedGames } = savedSlice.actions
export default savedSlice.reducer
