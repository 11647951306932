import { configureStore } from '@reduxjs/toolkit'
import gameReducer from './game/gameSlice'
import allGameReducer from './games/allGameSlice'
import loginReducer from './login/loginSlice'
// import historyReducer from './user/userSlice'
import { apiSlice } from './api/apiSlice'
import reelsReducer from './reels/reelsSlice'
import savedRReducer from './SavedGames/savedSlice'
import deleteReducer from './DeleteGames/deleteSlice'
import continuePlayReducer from './ContinuePlay/continuePlaySlice'
import categoryReducer from './ViewAll/categorySlice'
import categoryIdReducer from './categoryId/categoryIdSlice'
import allGamesReducer from './allGames/allGamesSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    game: gameReducer,
    games: allGameReducer,
    login: loginReducer,
    reels: reelsReducer,
    savedR: savedRReducer,
    deleteR: deleteReducer,
    continuePlay: continuePlayReducer,
    allCategory: categoryReducer,
    allCategoryId: categoryIdReducer,
    allGames: allGamesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})
