import { useRef, useState, useEffect } from 'react'
import { useOnScreen } from '../../../hooks/useOnScreen'
import { Link } from 'react-router-dom'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import classNames from 'classnames'
import { useIsOverflow } from '../../../hooks/useOverFlow'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

export const CategoryComponent = ({
  games,
  categoryId,
  totalpages,
  fetchSingleCategory,
  handleAllGamesClicked,
}) => {
  const toBeIntersectedImgRef = useRef(null)
  const isVisible = useOnScreen(toBeIntersectedImgRef, '100% 0% 100% 0%')
  const isLarge = useMediaQuery('(min-width:579px)')

  const [categoryGames, setCategoryGames] = useState(games.games)
  const [currentPage, setCurrentPage] = useState(1)
  const scrollRef = useRef(null)

  const isOverflow = useIsOverflow(scrollRef)

  const fetchNextPage = async (categoryId, pg) => {
    try {
      const data = await fetchSingleCategory(categoryId, pg)
      setCategoryGames((prev) =>
        !!data?.results?.games && data?.results?.games?.length > 0
          ? [...prev, ...data?.results?.games]
          : prev
      )
      setCurrentPage((prev) => prev + 1)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isVisible && totalpages?.[categoryId] >= currentPage + 1) {
      fetchNextPage(categoryId, currentPage + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const scroll = (offSet) => {
    scrollRef.current.scrollLeft += offSet
  }

  return (
    <>
      <p className="text-lg text-black font-bold leading-6 sm:pl-0 pl-2 pt-4">
        {games.name}
      </p>

      <div className="relative">
        <div
          className="wrapper h-36 flex overflow-y-hidden overflow-x-scroll gap-2 sm:pl-0 pl-2 scroll-smooth group"
          id={`category-${categoryId}`}
          ref={scrollRef}
        >
          {isOverflow && isLarge && (
            <div
              className={classNames(
                'absolute hidden group-hover:block left-9 top-12 z-10 cursor-pointer carousel-button text-white opacity-50 transition-opacity duration-300'
              )}
              onClick={() => scroll(-600)}
            >
              <FaChevronLeft className="text-white w-11 h-11 items-center align-middle rounded-full bg-black p-2.5" />
            </div>
          )}
          {categoryGames?.map((data, idx) => {
            return (
              <div key={data.uuid}>
                <div
                  className="w-[144px] h-[144px] aspect-square rounded-xl"
                  onClick={() =>
                    handleAllGamesClicked(data.name, data.uuid, data.updated_at)
                  }
                >
                  <Link to={`${data.slug}`} state={{ from: '/' }}>
                    {idx === categoryGames?.length - 4 && data.slug ? (
                      <img
                        src={data.property.thumbnail}
                        className="w-40 h-36  rounded-xl"
                        alt="thumbnail"
                        ref={toBeIntersectedImgRef}
                      />
                    ) : (
                      <img
                        src={data.property.thumbnail}
                        className="w-40 h-36 all-games-images rounded-xl"
                        alt="thumbnail"
                      />
                    )}
                  </Link>
                </div>
              </div>
            )
          })}
          {isOverflow && isLarge && (
            <div
              className={`absolute hidden group-hover:block right-9 top-12 cursor-pointer items-center text-white opacity-50 transition-opacity duration-300`}
              onClick={() => scroll(600)}
            >
              <FaChevronRight className="text-white w-11 h-11 items-center align-middle rounded-full bg-black p-2.5" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
