import React from 'react'
import './App.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './app/view/Home/Home'
import Game from './app/view/Game/Game'
import ErrorPage from './app/view/ErrorPage/ErrorPage'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ScrollProvider } from './providers/scrollProvider'

function App() {
  return (
    <ScrollProvider>
      <SkeletonTheme>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/:slug" element={<Game />} />
            {/* More specific routes go here */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </SkeletonTheme>
    </ScrollProvider>
  )
}

export default App
