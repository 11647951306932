import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [], // Change from an empty array to null
  isLoading: false,
  isSuccess: false,
  totalpages: {},
}

export const categorySlice = createSlice({
  name: 'allCategory',
  initialState,
  reducers: {
    setAllCategory: (state, action) => {
      state.data = [...state.data, action.payload]
      state.totalpages = {
        ...state.totalpages,
        [`${action?.payload?.results?.uuid}`]: action?.payload?.total_page,
      }
      state.isSuccess = true
    },
  },
})

export const { setAllCategory } = categorySlice.actions
export default categorySlice.reducer
