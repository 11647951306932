import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
}

export const categoryIdSlice = createSlice({
  name: 'allCategoryId',
  initialState,
  reducers: {
    setAllCategoryId: (state, action) => {
      state.data = action.payload
      state.isSuccess = true
    },
  },
})

export const { setAllCategoryId } = categoryIdSlice.actions
export default categoryIdSlice.reducer
